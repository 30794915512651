import { useEffect, useState } from 'react';
import CardSlider from '../card-slider';
import type { CardItemType } from '../voi-card';
import { useRouter } from 'next/router';
import { name_to_url } from '../functions/name-to-url';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { apiGetHotelInfoList } from 'services';
import { wrapperCardSliderType } from 'types';
import { apiGetVillaInfoList } from 'services/villa';
import { useGetProperty } from '../hook/use-get-property';
import moment from 'moment';

const SliderWrapper = (props: wrapperCardSliderType) => {
    const { queryParams, type } = props;

    const [items, setItems] = useState<CardItemType[]>();
    const [rerender, setRerender] = useState(0);
    const router = useRouter();
    const languageJson = useLanguage();
    const { getKeyBasedOnLocale } = useGetProperty();

    useEffect(() => {
        (async () => {
            let params: any = {
                page: 1,
                perPage: 10,
            };
            if (queryParams) {
                params = { ...params, ...queryParams };
            }
            if (type === 'Hotel') {
                const { data } = await apiGetHotelInfoList(params);
                setItems(
                    data.result.map(hotel => ({
                        ...hotel,
                        link: `/${router.locale}/hotel/${hotel.cityName}/${hotel.hotelId}/${name_to_url(hotel.name)}`,
                        upTitle: hotel?.cityName,
                        title: hotel.name,
                        rating: hotel?.rating,
                        basePrice: hotel.defaultStartPrice,
                        discount: hotel.defaultShowingDiscountPtc ? hotel.defaultShowingDiscountPtc : 0,
                        reviewScore: hotel.reviewScore,
                        image: hotel.images ? hotel.images[0]?.link : undefined,
                        scaleOfPrice: languageJson.common.per_night,
                    }))
                );
            } else if (type === 'Villa') {
                const { data } = await apiGetVillaInfoList(params);
                setItems(
                    data.result.map(villa => ({
                        ...villa,
                        link: `/${router.locale}/villa/${villa.city.nameEn}/${villa.roomId}/${name_to_url(villa.roomNameEn)}?checkIn=${moment().locale('en').format('YYYY-MM-DD')}&checkOut=${moment().locale('en').add(1, 'day').format('YYYY-MM-DD')}&cityId=${
                            villa.cityId
                        }&cityLabel=${getKeyBasedOnLocale('name', villa.city)}`,
                        upTitle: getKeyBasedOnLocale('name', villa.city),
                        title: getKeyBasedOnLocale('roomName', villa),
                        basePrice: villa.totalPrice,
                        personCapacity: `${villa.personCapacity}-${villa.extraPersonCapacity + villa.personCapacity}`,
                        reviewScore: villa.rate ?? 0,
                        image: villa.galleryImagesUrl.length ? villa.galleryImagesUrl[0] : undefined,
                        scaleOfPrice: languageJson.common.per_night,
                    }))
                );
                setRerender(rerender + 1);
            }
        })();
    }, [router.locale]);

    return (
        <>
            {items && (
                <CardSlider
                    key={rerender}
                    {...props}
                    items={items}></CardSlider>
            )}
        </>
    );
};
export default SliderWrapper;
