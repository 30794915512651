import { CmsLandings, apiCmsGetCountries, apiCmsGetLandingDataBySlug, apiCmsGetLandingList } from '@travel-platform/commons/src/services/CMS';
import { apiGetTravelService } from '@travel-platform/commons/src/services/travel-service';
// import LandingLayout from 'components/common/layouts/landing-layout';
import { Departure, ToursResponseType, apiGetDepartures, apiGetTours } from '@travel-platform/commons/src/services/tours';
import { CmsCountries } from '@travel-platform/commons/src/types';
import { GetTravelServiceResponseType } from 'types';
import SimpleLayout from 'components/common/layouts/simple-layout';
import React from 'react';
import { GetBlogPostsResponse, apiGetBlogPosts } from 'services/blog';
import { BreadcrumbJsonLd, NextSeo } from 'next-seo';
import JsonLdPage from 'components/json-lds/page';
import { LANDING_URL } from 'components/common/constant/constant';
import LandingPageComponents from 'components/landing/components';

type Props = {
    params: { id: string };
    searchParams: { [key: string]: string | string[] | undefined };
};
export type LandingPageProps = {
    LandingList: CmsLandings[];
    landingData: CmsLandings;
    services: GetTravelServiceResponseType[];
    localServices: GetTravelServiceResponseType[];
    posts?: GetBlogPostsResponse[];
    departure?: Departure[];
    countries: CmsCountries[];
    isLanding?: boolean;
    slug: string | null;
    tours: ToursResponseType[];
};

export enum LANDINGS {
    HOTEL = 'hotel',
    FLIGHT = 'flight',
    TOUR = 'tour',
    VISA = 'visa',
}

export async function getStaticPaths({ locales }: any) {
    if (process.env.SKIP_BUILD_STATIC_GENERATION) {
        return {
            paths: [],
            fallback: 'blocking',
        };
    }

    const { data } = await apiCmsGetLandingList('ar', true);

    let paths: any = [];

    data.data.forEach(landing => {
        for (const locale of locales) {
            paths.push({
                params: {
                    slug: landing.slug?.split('/') || [],
                },
                locale,
            });
        }
    });
    return {
        paths,
        fallback: false,
    };
}

export const getStaticProps: any = async (context: any) => {
    const sMaxAge = 1 * 60;
    const allPromise = [apiCmsGetLandingList(context.locale, false), apiCmsGetLandingDataBySlug(context.params.slug?.join('/') || null, context.locale), apiGetBlogPosts({ per_page: 4, _embed: '' }), apiGetDepartures({ page: 1, detailed: 1 }, '/', context.locale), apiCmsGetCountries(context.locale)];

    type dataType = 'LandingList' | 'landingData' | 'countries' | 'services' | 'localServices' | 'posts' | 'departure';

    return await Promise.allSettled(allPromise)
        .then(async results => {
            const indexData: Record<number, dataType> = {
                0: 'LandingList',
                1: 'landingData',
                2: 'posts',
                3: 'departure',
                4: 'countries',
            };

            const objData: Record<dataType, any> = {
                LandingList: null,
                landingData: null,
                services: null,
                localServices: null,
                posts: null,
                departure: null,
                countries: null,
            };

            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    objData[indexData[index]] = Array.isArray(result.value?.data) ? result.value.data : result.value?.data?.data ? result.value.data.data : result.value.data;
                }
            });

            const { LandingList, landingData, posts, departure, countries } = objData;
            if (!landingData || landingData.length < 1) {
                return {
                    notFound: true,
                    revalidate: 1,
                };
            }

            // Get tours of a country filtered by country code in Cms landing
            // const { data: tours } = await apiGetTours({ page: 1, per_page: 15, detailed: 1, sortBy: 0, countries: [landingData[0].country] }, context.locale, '/v2/');

            // Get locale services of a country filtered by country code in Cms landing
            const { data: allServices } = await apiGetTravelService({
                componentOptions: true,
                travelServiceVariants: true,
                locale: context.locale,
            });

            const { data: localServices } = await apiGetTravelService({
                componentOptions: true,
                travelServiceVariants: true,
                isPrimary: false,
                locale: context.locale,
                targetCountryCode: landingData[0].country,
            });

            return {
                props: {
                    LandingList,
                    landingData: landingData[0],
                    posts,
                    departure,
                    services: allServices,
                    localServices,
                    slug: context.params.slug?.join('/') || null,
                    countries: countries,
                    tours: [],
                },
                revalidate: sMaxAge,
            };
        })
        .catch(console.log);
};
export const Landings: React.FC<LandingPageProps> = props => {
    const { landingData, slug } = props;
    const { seo } = landingData;
    return (
        <SimpleLayout>
            <NextSeo
                title={seo?.title}
                description={seo?.description}
                nofollow={slug === 'tours' || process.env.NEXT_PUBLIC_APP_ENV === 'beta' || process.env.NEXT_PUBLIC_APP_ENV === 'staging'}
                noindex={slug === 'tours' || process.env.NEXT_PUBLIC_APP_ENV === 'beta' || process.env.NEXT_PUBLIC_APP_ENV === 'staging'}
            />
            <JsonLdPage landingData={landingData} />
            <BreadcrumbJsonLd
                key={'bread-crumb-json-ld'}
                itemListElements={[
                    {
                        position: 1,
                        name: 'Home',
                        item: `${LANDING_URL}`,
                    },
                    {
                        position: 2,
                        name: landingData.title,
                        item: `${LANDING_URL}/${landingData.slug}`,
                    },
                ]}
            />
            <LandingPageComponents {...props} />
        </SimpleLayout>
    );
};

export default Landings;
